<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid" v-if="currentWebBotId == null">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Web Bots</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <!-- <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" /> -->
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="webBotListing" :lazy="true" :paginator="false"
                                :totalRecords="totalRecords" :loading="loading" class="p-datatable-users"
                                data-key="mbu1" :rowHover="true">
                                <Toolbar>
                                    <template v-slot:right>
                                        <Button label="Add New Web Bot" icon="pi pi-plus" @click="onAddChatBot" />
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 5%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column header="Web Bot Name" field="mbu2" headerStyle="width: 20%"></Column>
                                <Column header="Langauge" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">{{ data.mbu5 ? data.mbu5 : '-' }}</div>
                                    </template>
                                </Column>
                                <Column header="Web Bot URL" headerStyle="width: 30%">
                                    <template #body="{ data }">
                                        <div>{{ data.mbu6 ? data.mbu6 : '-' }}</div>
                                    </template>
                                </Column>
                                <Column header="Status" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div v-if="data.mbu3 == 1"> Active </div>
                                            <div v-else> Inactive</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 15%">
                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditChatBot(data)" />
                                        <!-- WebBotFlowSetting -->
                                        <Button icon="pi pi-cog" class="p-button-rounded "
                                            @click="onSetWebBotFlow(data, index)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit Chat Bot Dialog starts here -->
    <Dialog v-model:visible="isDialogOpen" :style="{ width: '800px' }" :modal="true" class="p-fluid"
        :header="dialogHeader">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="chatbotname">
                            Web Bot Name
                            <span class="p-invalid p-error"> *</span>
                        </label>
                        <InputText id="chatbotname" v-model.trim="chatbotname" required="true" maxlength="50" autofocus
                            :class="{ 'p-invalid': isSubmitted && !chatbotname }" />
                        <small class="p-invalid p-error" v-if="v$.chatbotname.$error">{{
                            v$.chatbotname.$errors[0].$message }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="languages">
                            Language
                            <span class="p-invalid p-error"> *</span>
                        </label>
                        <div class="flex justify-content-center">
                            <Checkbox v-model="languages_eng" inputId="languageEng" name="languages1" value="English"
                                :binary="true" />
                            <label for="languageEng" class="p-mr-2"> English </label>
                            <Checkbox v-model="languages_hin" inputId="languageHin" name="languages2" value="Hindi"
                                :binary="true" />
                            <label for="languageHin" class="p-mr-2"> Hindi </label>
                        </div>
                        <small class="p-invalid p-error" v-if="v$.languages.$error">{{ v$.languages.$errors[0].$message
                            }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="languageId">
                            Regional Language
                        </label>
                        <Dropdown id="languageId" showClear filter v-model="selectedLanguages"  :options="languageList"
                            optionLabel="label" placeholder="Select Language" appendTo="body"
                             />
                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <label for="webbotUrl">
                            Web Bot URL
                            <span class="p-invalid p-error"> *</span>
                        </label>
                        <InputText id="webbotUrl" v-model.trim="webbotUrl" required maxlength="100" autofocus
                            @input="validateWebbotUrl" :class="{ 'p-invalid': isSubmitted && !webbotUrl }" />
                        <small class="p-invalid p-error" v-if="v$.webbotUrl.$error">{{ v$.webbotUrl.$errors[0].$message
                            }}</small>
                        <small v-if="!isValidUrl" class="p-invalid p-error">The URL must start with a '/' followed by a
                            letter.</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label>Status <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="status_active" name="option" v-bind:value="1" v-model="statusType"
                                :checked="statusType == 1" />
                            <label for="status_active" class="p-mr-2">Active</label>
                            <RadioButton id="status_Inactive" name="option" v-bind:value="0" v-model="statusType"
                                :checked="statusType == 0" />
                            <label for="status_Inactive" class="p-mr-2">Inactive</label>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                class="p-button-text p-ml-auto" @click="addChatBot" style="width: 90px;"></Button>
        </template>
    </Dialog>
    <!-- Add/Edit chat bot Dialog ends here -->
    <WebBotFlowSetting :propsData=webBotData :propsData1=rowId @go_to_previous="handleButtonClick"
        v-if="currentWebBotId != null" />
    <!--:propsData2=webBotListing-->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
import { useRoute } from 'vue-router';
import WebBotFlowSetting from './WebBotFlowSetting.vue';

import { required, helpers } from '@vuelidate/validators';

export default {
    components: {
        WebBotFlowSetting
    },
    data() {
        return {
            webBotListing: [],
            v$: useValidate(),
            selectedLanguages: [],
            languageList: [],
            isDialogOpen: false,
            isSubmitted: false,
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            chatbotname: '',
            webbotUrl: '',
            languages_eng: false,
            languages_hin: false,
            statusType: 1,
            chatbotId: 0,
            dialogHeader: "",
            localClientName: '',
            routeParam: '',
            showSettingsStatus: false,
            webBotData: [],
            rowId: 0,
            isValidUrl: true,
            currentWebBotId: null,

        };
    },
    validations() {
        return {
            chatbotname: { required: helpers.withMessage('Please enter chat bot name', required) },
            webbotUrl: { required: helpers.withMessage('Please enter web bot URL', required) },
            // langauges: { required: helpers.withMessage('Please select atleast 1 language', required) }
            languages_eng: { required },
            languages_hin: { required },
            languages: {
                isValid: {
                    $validator: helpers.withMessage(
                        'At least one language must be selected',
                        function () {
                            return this.languages_eng || this.languages_hin || (this.selectedLanguages && this.selectedLanguages.length > 0);
                        }
                    ),
                },
            },

        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        if (localStorage.localClientName) {
            this.localClientName = localStorage.localClientName;
        }
        if (localStorage.getItem('webBotId') !== null) {
            this.currentWebBotId = localStorage.webBotId;
        }
        this.getLanguages();
        this.getchatbotListing({ clientId: this.routeParam });
    },
    watch: {
        webbotUrl() {
            this.validateWebbotUrl();
        }
    },

    methods: {
        validateWebbotUrl() {
            const urlPattern = /^\/[a-zA-Z]/;
            this.isValidUrl = urlPattern.test(this.webbotUrl);
        },
        onSetWebBotFlow(e, index) {
            this.showSettingsStatus = true;
            localStorage.webBotId = e.mbu1;
            localStorage.setItem('webBotData', JSON.stringify(e));
            this.currentWebBotId = e.mbu1;
            this.webBotData = e;
            this.rowId = index;
        },
        handleButtonClick() {
            this.showSettingsStatus = false;
            this.webBotData = null;
            localStorage.removeItem('webBotId');
            localStorage.removeItem('webBotData');

            this.currentWebBotId = null;
            // localStorage.webBotId = null;
            this.getchatbotListing({ clientId: this.routeParam })

        },
        getLanguages() {
            this.ApiService.getLanguagesExceptHindiAndEnglish().then((data) => {
                if (data.status == 200) {
                    this.languageList = data.data;
                } else {
                    this.languageList = '';
                }
            });
        },
        onAddChatBot() {
            this.langauges_eng = '';
            this.langauges_hin = '';
            this.v$.$reset();
            this.xyz = "";
            this.dialogHeader = 'Add Chat Bot';
            this.isDialogOpen = true;
            this.chatbotname = '';
            this.webbotUrl = '';
            this.selectedLanguages = '';
        },
        onEditChatBot(data) {
            this.dialogHeader = "Edit Chat Bot";
            this.isDialogOpen = true;
            this.chatbotId = data.mbu1;
            this.chatbotname = data.mbu2;
            this.webbotUrl = data.mbu6;
            this.languages_hin = false;
            this.languages_eng = false;
            if (data && data.mbu4) {
                var str_array = data.mbu4.split(',');
                for (let i = 0; i < str_array.length; i++) {
                    let vals = this.languageList.filter(function (item) {
                        return item.value == str_array[i];
                    });
                    if (vals.length > 0) {
                        this.selectedLanguages = { value: vals[0]['value'], label: vals[0]['label'] };
                    }
                    if (str_array[i] == 5) {
                        this.languages_hin = true;
                    }
                    if (str_array[i] == 1) {
                        this.languages_eng = true;
                    }
                }
            } else {
                this.selectedLanguages = '';
            }
            this.statusType = data.mbu3;
        },
        async getchatbotListing(params) {
            try {
                const result = await this.ApiService.getchatbotListing({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.webBotListing = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });

            }
        },
        async addChatBot() {
            try {
                this.isSubmitted = true;
                this.v$.$validate();

                if (!this.v$.$error && this.isValidUrl) {
                    this.loading = this.showLoader = true;
                    let mbu4 = [];
                    let mbu5 = [];

                    // Process `languages_eng` and `languages_hin` as boolean values
                    if (this.languages_eng) {
                        mbu4.push(1);
                        mbu5.push('English');
                    }

                    if (this.languages_hin) {
                        mbu4.push(5);
                        mbu5.push('Hindi');
                    }
                    if (this.selectedLanguages) {
                        mbu4.push(this.selectedLanguages.value);
                        mbu5.push(this.selectedLanguages.label);
                    }

                    // Convert arrays to comma-separated strings
                    let mbu4CommaSep = mbu4.join(','); // Use join() instead of toString() for clarity
                    let mbu5CommaSep = mbu5.join(','); // Use join() instead of toString() for clarity

                    // Create parameters object
                    let params = {
                        mbu1: this.chatbotId,
                        mbu2: this.chatbotname,
                        mbu4: mbu4CommaSep,
                        mbu5: mbu5CommaSep,
                        mbu3: this.statusType,
                        mbu6: this.webbotUrl,
                        clientId: this.routeParam,
                    };

                    // Make API call
                    let result = await this.ApiService.createUpdateChatBotIds(params);

                    // Handle result
                    this.loading = this.showLoader = this.isDialogOpen = false;
                    if (result.success) {
                        // Reset fields on success
                        this.chatbotId = 0;
                        this.chatbotname = '';
                        this.webbotUrl = '';
                        this.selectedLanguages = [];
                        this.statusType = 1;
                        this.languages_hin = false;
                        this.languages_eng = false; // Ensure to reset this as well
                        this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                        this.getchatbotListing({ clientId: this.routeParam });
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                    }
                }
            } catch (error) {
                this.loading = this.showLoader = this.isDialogOpen = false;
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
            }
        },

        goToNext() {
            router.push({
                name: "listTemplateDescription",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        goToDashboard() {
            router.push({
                name: "web-bot-template-settings-dashboard",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
    }
}

</script>

<style scoped></style>
